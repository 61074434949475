import { Routes, Route, BrowserRouter as Router, Navigate } from 'react-router-dom';
import VigiditStartPage from './pages/VigiditStartPage';
import VigiditCategoryPage from './pages/VigiditCategoryPage';
import VigiditAgeRangePage from './pages/VigiditAgeRangePage';
import VigiditProfesionalPage from './pages/VigiditProfesionalPage';
import VigiditSourcesPage from './pages/VigiditSourcesPage';

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<VigiditStartPage />} />
        <Route path="/age-ranges/:rangeId" element={<VigiditAgeRangePage />} />
        <Route exact path="/age-ranges" element={<VigiditProfesionalPage />} />
        <Route path="/category/:categoryId" element={<VigiditCategoryPage />} />
        <Route exact path="/sources" element={<VigiditSourcesPage />} />
      </Routes>
    </Router>
  );
}

export default App;
