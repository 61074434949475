import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
import DateInput from "./components/DateInput";
import RadioButtons from "./components/RadioButtons";
import SelectInput from "./components/SelectInput";
import Accordion from "./components/Accordion";
import moment from "moment";
import api from "../api";

const weeksOptions = [
    24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39,
];

export default function VigiditStartPage() {
    const [loading, setLoading] = useState(true);
    const [birthdate, setBirthdate] = useState(moment());
    const [gestationWeeks, setGestationWeeks] = useState(39);
    const [isPremature, setIsPremature] = useState(0);
    const [weeksAge, setWeeksAge] = useState(0);
    const [correctedAge, setCorrectedAge] = useState(0);
    const [vigidit, setVigidit] = useState({
        startVideo: "",
        startMessage: "",
        sources: "",
        disclaimer: "",
    });

    useEffect(() => {
        api.configurations
            .getOne("vigidit")
            .then((response) => {
                setVigidit(JSON.parse(response.value));
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        const startDate = moment(birthdate);
        const endDate = moment();

        setWeeksAge(Math.abs(startDate.diff(endDate, "weeks")));
        let gestation = 40 - gestationWeeks;
        let weeks = weeksAge - gestation;
        setCorrectedAge(parseInt(weeks / 4));
    }, [birthdate, gestationWeeks]);

    return (
        <main>
            {loading ? (
                <div className="flex h-full items-center justify-center ">
                    <div className="w-16 h-16 border-b-2 border-indigo-900 rounded-full animate-spin"></div>
                </div>
            ) : (
                <>
                    <header className="p-5 bg-gray-50 flex flex-col justify-center items-center gap-y-2">
                        <img
                            className="h-20 w-auto"
                            src="/dit-logo.png"
                            alt="Workflow"
                        />
                        <h1 className="font-bold text-black text-3xl">
                            Vigilando el Desarrollo
                        </h1>
                    </header>
                    <section className="bg-gray-50">
                        <div className="sm:mx-auto sm:w-full sm:max-w-md p-8">
                            <ReactPlayer
                                url={vigidit.startVideo}
                                width="100%"
                                height="200px"
                                controls={true}
                            />
                            <p className="pt-6 whitespace-pre-wrap">
                                {vigidit.startMessage}
                            </p>
                        </div>
                    </section>
                    <form className="p-5 border-b-2 border-gray-300 sm:mx-auto sm:w-full sm:max-w-md">
                        <DateInput
                            defaultValue={birthdate}
                            handleChange={(fiels, value) => setBirthdate(value)}
                            containerClassName="w-full"
                            name="birthdate"
                            label="Fecha de nacimiento del niño"
                        />
                        <RadioButtons
                            containerClassName="mt-4"
                            label="¿Su bebé nació prematuro?"
                            name="isPremature"
                            handleChange={(field, value) =>
                                setIsPremature(value)
                            }
                            defaultChecked={isPremature}
                            options={[
                                { id: 0, title: "No" },
                                { id: 1, title: "Si" },
                            ]}
                        />
                        {isPremature == 1 ? (
                            <SelectInput
                                handleChange={(field, value) =>
                                    setGestationWeeks(value)
                                }
                                containerClassName="w-full mt-4"
                                name="gestationWeeks"
                                label="¿En qué semana nació su bebé?"
                                options={weeksOptions.map((week) => {
                                    return (
                                        <option key={week} value={week}>
                                            {week}
                                        </option>
                                    );
                                })}
                            />
                        ) : (
                            ""
                        )}
                        <Link
                            to={"/age-ranges/" + correctedAge}
                            className="mt-4 inline-flex w-full items-center justify-center px-4 py-3 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            Acceder
                        </Link>
                    </form>
                    <div className="p-8">
                        <Link
                            to="/age-ranges"
                            className="font-semibold text-medium text-indigo-600 hover:text-indigo-700 hover:underline block text-center"
                        >
                            Acceso profesionales
                        </Link>
                        <Link
                            to="/sources"
                            className="font-semibold mt-3 text-medium text-indigo-600 hover:text-indigo-700 hover:underline block text-center"
                        >
                            Fuentes de información
                        </Link>
                    </div>
                    <Accordion text={vigidit.disclaimer} />
                </>
            )}
        </main>
    );
}
