import React from "react";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/outline";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export default function Accordion(props) {
    return (
        <div className="px-5 mb-16 sm:mx-auto sm:w-full sm:max-w-md">
            <dl className="mt-4 space-y-6 divide-y divide-gray-200">
                    <Disclosure as="div">
                        {({ open }) => (
                            <>
                                <dt className="text-base">
                                    <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                                        <span className="font-medium text-gray-900">
                                            Notas aclaratorias
                                        </span>
                                        <span className="ml-6 h-7 flex items-center">
                                            <ChevronDownIcon
                                                className={classNames(
                                                    open
                                                        ? "-rotate-180"
                                                        : "rotate-0",
                                                    "h-6 w-6 transform"
                                                )}
                                                aria-hidden="true"
                                            />
                                        </span>
                                    </Disclosure.Button>
                                </dt>
                                <Disclosure.Panel
                                    as="dd"
                                    className="mt-2 pr-12"
                                >
                                    <p className="text-base text-gray-500 whitespace-pre-wrap mb-4">
                                        {props.text}
                                    </p>
                                </Disclosure.Panel>
                            </>
                        )}
                    </Disclosure>
            </dl>
        </div>
    );
}
