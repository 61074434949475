import React, { useState, useEffect } from "react";
import VigiditHeader from "./components/VigiditHeader";
import api from "../api";

export default function VigiditSourcesPage() {
    const [loading, setLoading] = useState(true);
    const [vigidit, setVigidit] = useState({
        startVideo: "",
        startMessage: "",
        sources: "",
        disclaimer: "",
    });

    useEffect(() => {
        api.configurations
            .getOne("vigidit")
            .then((response) => {
                setVigidit(JSON.parse(response.value));
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }, []);

    return (
        <>
            <VigiditHeader />
            {loading ? (
                <div className="flex h-full items-center justify-center ">
                    <div className="w-16 h-16 border-b-2 border-indigo-900 rounded-full animate-spin"></div>
                </div>
            ) : (
                <section className="p-5 sm:mx-auto sm:w-full sm:max-w-md">
                    <h1 className="font-bold text-3xl text-center mb-6">
                        Fuentes de información
                    </h1>
                    <p className="pt-6 whitespace-pre-wrap">
                        {vigidit.sources}
                    </p>
                </section>
            )}
        </>
    );
}
