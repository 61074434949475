import React, { useState, useEffect } from "react";
import VigiditHeader from "./components/VigiditHeader";
import api from "../api";
import { Link } from "react-router-dom";

export default function VigiditProfesionalPage() {
    const [ageRanges, setAgeRanges] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        api.ageRanges
            .getMulti()
            .then((response) => {
                setAgeRanges(response.reverse());
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }, []);

    return (
        <>
            <VigiditHeader />
            {loading ? (
                <div className="flex h-full items-center justify-center ">
                    <div className="w-16 h-16 border-b-2 border-indigo-900 rounded-full animate-spin"></div>
                </div>
            ) : (
                <section className="p-5 sm:mx-auto sm:w-full sm:max-w-md">
                    <h1 className="text-indigo-600 py-4 border-b-2 text-lg mb-4">
                        Rango de edad
                    </h1>
                    {ageRanges.length > 0 ? (
                        ageRanges.map((range) => {
                            return (
                                <article
                                    key={range.id}
                                    className="mt-4 h-24 rounded-md overflow-hidden shadow-sm cursor-pointer bg-indigo-100 hover:bg-indigo-200"
                                >
                                    <Link
                                        to={`/age-ranges/${range.ageInMonthsMax}`}
                                        className="h-full flex items-center px-4"
                                    >
                                        <span className="text-black font-semibold text-lg ml-4">
                                            {range.ageRange}
                                        </span>
                                    </Link>
                                </article>
                            );
                        })
                    ) : (
                        <p className="text-medium text-gray-400 pt-4">
                            No hay categorías aún.
                        </p>
                    )}
                </section>
            )}
        </>
    );
}
