import React from "react"

export default function RadioButtons(props) {
    return (
        <div className={props.containerClassName}>
            <label className="text-sm font-medium text-gray-700">{props.label}</label>
            <p className="text-sm leading-5 text-gray-500">{props.description}</p>
            <fieldset className="mt-4">
                <legend className="sr-only">{props.label}</legend>
                <div className="flex gap-x-6">
                    {props.options.map((option) => (
                        <div key={option.id} className="flex items-center">
                            <input
                                onChange={(event) => props.handleChange(props.name, event.target.value)}
                                id={option.id}
                                name={props.name}
                                type="radio"
                                value={option.id}
                                defaultChecked={option.id == props.defaultChecked}
                                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                            />
                            <label htmlFor={option.id} className="ml-3 block text-sm font-medium text-gray-700">
                                {option.title}
                            </label>
                        </div>
                    ))}
                </div>
            </fieldset>
        </div>
    )
}