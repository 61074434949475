import React from "react";

export default function TextInput(props) {

    const today = new Date().toISOString().substring(0, 10)

    return (
        <div className={props.containerClassName}>
            <label htmlFor={props.name} className="block text-sm leading-5 font-medium text-gray-700">
                {props.label}
            </label>
            <div className="mt-1">
                <input
                    type="date"
                    onChange={(event) => props.handleChange(props.name, event.target.value)}
                    name={props.name}
                    id={props.name}
                    defaultValue={props.defaultValue ? props.defaultValue : today}
                    className={props.className ? props.className : "shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"}
                />
            </div>
        </div>
    )
}