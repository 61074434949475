import React, { useState, useEffect } from "react";
import VigiditHeader from "./components/VigiditHeader";
import api from "../api";
import {
    ChevronLeftIcon, DocumentDownloadIcon
} from '@heroicons/react/outline'
import { useNavigate, useParams } from "react-router-dom";
import './content.css';

export default function VigiditCategoryPage() {
    const [category, setCategory] = useState([]);
    const [loading, setLoading] = useState(true);
    const { categoryId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);
        api.categories
            .getOne(categoryId)
            .then((response) => {
                setCategory(response);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }, []);

    return (
        <>
            <VigiditHeader />
            {loading ? (
                <div className="flex h-full items-center justify-center ">
                    <div className="w-16 h-16 border-b-2 border-indigo-900 rounded-full animate-spin"></div>
                </div>
            ) : (
                <section className="p-5 sm:mx-auto sm:w-full sm:max-w-md">
                    <div className="flex gap-x-2 cursor-pointer" onClick={() => navigate(-1)}>
                        <ChevronLeftIcon className="w-4 text-gray-400" />
                        <span className="text-base text-gray-400">Volver</span>
                    </div>
                    <article className="mt-4">
                        <h1 className="font-bold text-2xl">{category?.name}</h1>
                        <div className="py-2">
                            <span className="block text-indigo-600">{category?.ageRangeName}</span>
                        </div>
                        <div id="content" className="mb-16 py-2" dangerouslySetInnerHTML={{
                            __html: category?.content
                        }}></div>
                    </article>
                    {
                        category.document ?
                            <div className="mt-6">
                                <a
                                    href={category?.document}
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    type="button"
                                    className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-semibold rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
                                >
                                    <DocumentDownloadIcon
                                        className="-ml-1 mr-2 h-5 w-5"
                                        aria-hidden="true"
                                    />
                                    Descargar archivo adjunto
                                </a>
                            </div> : ""
                    }
                </section>
            )}
        </>
    );
}
