import axios from "axios";
//import QueryString from "qs";

const errorCodes = {
    badRequest: 'badRequest',
    unauthorized: 'unauthorized',
    forbidden: 'forbidden',
    notFound: 'notFound',
    serverError: 'serverError',
    unexpected: 'unexpected',
    invalidCredentials: 'invalidCredentials',
}

class API {
    constructor(collection) {
        this.collectionUrl = `${process.env.REACT_APP_API_BASE_URL}/${collection}`;
    }

    _handleError(error) {
        if (error.response?.status === 400)
            return Promise.reject({ code: errorCodes.badRequest, message: error.response.data })
        if (error.response?.status === 401)
            return Promise.reject(errorCodes.unauthorized)
        if (error.response?.status === 403)
            return Promise.reject(errorCodes.forbidden)
        if (error.response?.status === 404)
            return Promise.reject(errorCodes.notFound)
        if (500 <= error.response?.status <= 599)
            return Promise.reject(errorCodes.serverError)
        return Promise.reject(errorCodes.unexpected)
    }

    async getMulti(ordering = null, search = null, limit = null, offset = null, extra = {}) {
        const params = { limit, offset, ...extra }
        if (ordering)
            params.ordering = ordering;
        if (search && search.length > 0)
            params.search = search;
        try {
            const response = await axios.get(`${this.collectionUrl}/`, { params },);
            return Promise.resolve(response.data);
        } catch (e) {
            return Promise.reject(e.response.data)
        }
    }

    async getOne(id) {
        try {
            const response = await axios.get(`${this.collectionUrl}/${id}/`);
            return Promise.resolve(response.data)
        } catch (e) {
            return Promise.reject(e.response.data)
        }
    }

    async create(data) {
        try {
            const response = await axios.post(`${this.collectionUrl}/`, data);
            return Promise.resolve(response.data)
        } catch (e) {
            return Promise.reject(e.response.data)
        }
    }

    async update(id, data, partial = false) {
        try {
            let response = null;
            if (partial)
                response = await axios.patch(`${this.collectionUrl}/${id}/`, data);
            else
                response = await axios.put(`${this.collectionUrl}/${id}/`, data);
            return Promise.resolve(response.data);
        } catch (e) {
            return Promise.reject(e.response.data)
        }
    }

    async delete(id) {
        try {
            const response = await axios.delete(`${this.collectionUrl}/${id}/`)
            return response
        } catch (e) {
            return Promise.reject(e.response.data)
        }
    }
}

class FormDataAPI extends API {
    async createData(data) {
        try {
            const response = await axios.post(
                `${this.collectionUrl}/`,
                data,
                {
                    headers: { "content-type": "multipart/form-data" },
                })
            return Promise.resolve(response.data)
        } catch (e) {
            return Promise.reject(errorCodes.invalidCredentials)
        }
    }

    async updateData(id, data, partial = false) {
        try {
            let response = null;
            if (partial)
                response = await axios.patch(
                    `${this.collectionUrl}/${id}/`,
                    data,
                    {
                        headers: { "content-type": "multipart/form-data" },
                    }
                );
            else
                response = await axios.put(
                    `${this.collectionUrl}/${id}/`,
                    data,
                    {
                        headers: { "content-type": "multipart/form-data" },
                    }
                );
            return Promise.resolve(response.data);
        } catch (e) {
            return Promise.reject(e.response.data)
        }
    }
}

const api = {
    categories: new FormDataAPI('vigidit/categories'),
    ageRanges: new API('vigidit/age-ranges'),
    images: new API('vigidit/images'),
    configurations: new API('configurations'),
}

export default api;
export { errorCodes, API };