import React, { useState, useEffect } from "react";
import VigiditHeader from "./components/VigiditHeader";
import api from "../api";
import ReactPlayer from "react-player";
import { Link, useParams } from "react-router-dom";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function VigiditAgeRangePage() {
    const [categories, setCategories] = useState([]);
    const [ageRange, setAgeRange] = useState({
        message: "",
        videoInSpanish: "",
        videoInGuarani: "",
        ageRange: "",
    });
    const [loading, setLoading] = useState(true);
    const { rangeId } = useParams();
    const [tab, setTab] = useState("Avañe'é");
    const [tabs, setTabs] = useState([
        { name: "Avañe'é", current: true },
        { name: "Español", current: false },
    ]);

    const changeTab = ((value) => {
        tabs[tabs.findIndex((tab) => tab.current)].current = false
        tabs[tabs.findIndex((tab) => tab.name === value)].current = true
        setTab(tabs[tabs.findIndex((tab) => tab.current)].name)
    })

    useEffect(() => {
        api.ageRanges
            .getMulti(null, null, null, null, { age: rangeId })
            .then((response) => {
                console.log(response);
                setCategories(response[0].categories);
                setAgeRange({
                    message: response[0].message,
                    videoInSpanish: response[0].videoInSpanish,
                    videoInGuarani: response[0].videoInGuarani,
                    ageRange: response[0].ageRange,
                });
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }, []);

    return (
        <>
            <VigiditHeader />
            {loading ? (
                <div className="flex h-full items-center justify-center ">
                    <div className="w-16 h-16 border-b-2 border-indigo-900 rounded-full animate-spin"></div>
                </div>
            ) : (
                <>
                    <section className="p-5">
                        <p className="sm:mx-auto sm:w-full sm:max-w-md text-center">
                            "{ageRange?.message}"
                        </p>
                    </section>
                    <section className="p-5 sm:mx-auto sm:w-full sm:max-w-md">
                        <h1 className="font-bold text-2xl text-center">
                            {ageRange?.ageRange}
                        </h1>
                        <div className="my-4">
                            <div className="hidden sm:block">
                                <div className="border-b border-gray-200">
                                    <nav
                                        className="-mb-px flex space-x-8"
                                        aria-label="Tabs"
                                    >
                                        {tabs.map((tab) => (
                                            <button
                                                key={tab.name}
                                                className={classNames(
                                                    tab.current
                                                        ? "border-indigo-500 text-indigo-600"
                                                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                                    "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm w-full"
                                                )}
                                                onClick={() =>
                                                    changeTab(tab.name)
                                                }
                                                aria-current={
                                                    tab.current
                                                        ? "page"
                                                        : undefined
                                                }
                                            >
                                                {tab.name}
                                            </button>
                                        ))}
                                    </nav>
                                </div>
                            </div>
                            {tab == "Avañe'é" && (
                                <div className="p-4">
                                    <ReactPlayer
                                        url={ageRange?.videoInGuarani}
                                        width="100%"
                                        height="auto"
                                        controls={true}
                                    />
                                </div>
                            )}
                            {tab == "Español" && (
                                <div className="p-4">
                                    <ReactPlayer
                                        url={ageRange?.videoInSpanish}
                                        width="100%"
                                        height="auto"
                                        controls={true}
                                    />
                                </div>
                            )}
                        </div>
                    </section>
                    <section className="px-5 mb-5 sm:mx-auto sm:w-full sm:max-w-md">
                        <p className="text-indigo-600 pb-4 border-b-2">
                            Categorías
                        </p>
                        {categories.length > 0 ? (
                            categories.map((category) => {
                                return (
                                    <Link
                                        to={`/category/${category?.id}`}
                                        key={category?.id}
                                        className="block mt-4 h-24 rounded-md overflow-hidden shadow-sm cursor-pointer"
                                    >
                                        <div
                                            className="h-full flex items-center px-4"
                                            style={{
                                                backgroundColor: `${category?.color}55`,
                                            }}
                                        >
                                            <div
                                                className="rounded-md w-12 h-12 flex justify-center items-center"
                                                style={{
                                                    backgroundColor:
                                                        category?.color,
                                                }}
                                            >
                                                <img
                                                    className="h-8 w-auto"
                                                    src={category?.logo}
                                                    alt={category?.name}
                                                />
                                            </div>
                                            <div className="ml-4">
                                                <h3 className="text-black font-semibold text-lg">
                                                    {category?.name}
                                                </h3>
                                                <p>{category?.description}</p>
                                            </div>
                                        </div>
                                    </Link>
                                );
                            })
                        ) : (
                            <p className="text-medium text-gray-400 pt-4">
                                No hay categorías aún.
                            </p>
                        )}
                    </section>
                </>
            )}
        </>
    );
}
